import Check from '@mui/icons-material/Check'
import Link from '@mui/icons-material/Link'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Box,
  Typography,
  Button,
  DialogActions,
} from '@mui/material'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

interface CopyLinkDialogProps {
  open: boolean
  arbetsgivarinfoUrl: string
}

const CopyLinkDialog = (props: CopyLinkDialogProps) => {
  const { open, arbetsgivarinfoUrl } = props
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const history = useHistory()

  const copyLinkToClipboard = () => {
    navigator.clipboard
      .writeText(arbetsgivarinfoUrl)
      .then(() => {
        setIsLinkCopied(true)
      })
      .catch(() => {
        setIsLinkCopied(false)
      })
  }

  const handleCloseLinkDialog = () => {
    history.replace('/trr-foretag/dina-lankar')
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Länken har skapats</DialogTitle>
      <DialogContent>
        <DialogContentText mb={3}>
          Du hittar länken nedanför. Dela länken till berörda medarbetare på det
          sätt som passar er bäst.
        </DialogContentText>
        <TextField
          label="Kopiera länk"
          InputProps={{
            startAdornment: <Link />,
            endAdornment: isLinkCopied ? (
              <Box display="flex">
                <Check />
                <Typography variant="body2" sx={{ color: 'success.main' }}>
                  Kopierad
                </Typography>
              </Box>
            ) : (
              <Button variant="text" size="small" onClick={copyLinkToClipboard}>
                Kopiera
              </Button>
            ),
          }}
          defaultValue={arbetsgivarinfoUrl}
          contentEditable={false}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" size="small" onClick={handleCloseLinkDialog}>
          Fortsätt
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CopyLinkDialog
