import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Button,
  DialogActions,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material'

interface PreviewArbetsgivarinfoDialogProps {
  body: string
  open: boolean
  setOpen: (open: boolean) => void
}

const PreviewArbetsgivarinfoDialog = (
  props: PreviewArbetsgivarinfoDialogProps
) => {
  const { body, open, setOpen } = props

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog data-testid="preview-dialog" open={open} fullWidth maxWidth="md">
      <DialogTitle>Förhandsgranskning</DialogTitle>
      <DialogContent>
        <DialogContentText mb={3}>
          Här ser du vad tjänstemän som loggar via länken kommer att se.
          Meddelandet i den gröna rutan är synlig när användaren loggat in.
        </DialogContentText>
        <Box
          sx={{
            backgroundColor: 'surface.grey',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingY: 5,
            paddingX: 12,
          }}
        >
          <Card sx={{ backgroundColor: 'surface.green' }}>
            <CardHeader title="Information från din arbetsgivare" />
            <CardContent>{body}</CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" size="small" onClick={handleClose}>
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewArbetsgivarinfoDialog
