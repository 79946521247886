import { Box } from '@mui/material'
import ViewLinks from './features/ViewLinks'
import CreateLink from './features/CreateLink'
import UpdateLink from './features/UpdateLink'
import { useCurrentKey } from '@trr/app-shell-data'

const MittForetagView = () => {
  const key = useCurrentKey()
  return (
    // TODO create link view and creation flows when Figma is finished
    <Box data-testid="mitt-foretag-view">
      {key === 'uppdatera' && <UpdateLink />}
      {key === 'skapa' && <CreateLink />}
      {key === 'arbetsgivarinfo' && <ViewLinks />}
    </Box>
  )
}

export default MittForetagView
