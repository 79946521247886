import useLinkValidation from 'src/hooks/useLinkValidation'
import { CircularProgress } from '@mui/material'
import { LinkValidated, LinkInvalid } from './components'

const ArbetsgivarinfoView = () => {
  const { isLinkValidated, isLoading, linkId } = useLinkValidation()

  if (isLoading) return <CircularProgress data-testid="linkid-loading" />

  if (isLinkValidated) {
    return <LinkValidated linkId={linkId} />
  }

  return <LinkInvalid />
}

export default ArbetsgivarinfoView
