import { Box } from '@mui/material'

interface BasePageProps {
  mainComponent: React.ReactNode
  sideComponent: React.ReactNode
}

const BasePage = (props: BasePageProps) => {
  const { mainComponent, sideComponent } = props
  return (
    <Box>
      <Box display="flex" gap={5} flexDirection={{ xs: 'column', lg: 'row' }}>
        <Box flexGrow={1}>{mainComponent}</Box>
        <Box>{sideComponent}</Box>
      </Box>
    </Box>
  )
}

export default BasePage
