/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box, Button } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ActivationDialog, BaseForm } from 'src/components'
import { usePutArbetsgivarinformationMutation } from 'src/services'
import { Arbetsgivarinfo, FormValues } from 'src/types'
import { getArbetsgivarinfoData } from 'src/utils'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { useHistory } from 'react-router-dom'

const UpdateLinkFormFields = ({
  previousFormData,
  linkId,
}: {
  previousFormData: Arbetsgivarinfo
  linkId: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const formProps = useForm<FormValues>({
    defaultValues: {
      startTime: new Date(previousFormData.startTime),
      endTime: new Date(previousFormData.endTime),
      text: previousFormData.text,
      name: previousFormData.name,
    },
  })
  const history = useHistory()

  const [putArbetsgivarinfo, result] = usePutArbetsgivarinformationMutation()

  const onSubmit = async (data: FormValues) => {
    const arbetsgivarinfo = getArbetsgivarinfoData(
      data,
      previousFormData.organizationNumber
    )
    arbetsgivarinfo.isEnabled = previousFormData.isEnabled

    await putArbetsgivarinfo({ body: arbetsgivarinfo, linkId }).finally(() => {
      history.replace('/trr-foretag/dina-lankar')
    })
  }

  const onSetActivityAction = async (data: FormValues) => {
    const arbetsgivarinfo = getArbetsgivarinfoData(
      data,
      previousFormData.organizationNumber
    )
    arbetsgivarinfo.isEnabled = !previousFormData.isEnabled

    await putArbetsgivarinfo({ body: arbetsgivarinfo, linkId })
  }

  return (
    <>
      <Box
        component="form"
        onSubmit={formProps.handleSubmit(onSubmit)}
        data-testid="create-link-form"
      >
        <BaseForm
          isError={result.isError}
          formProps={formProps}
          submitText="Uppdatera länk"
          DeactivateButton={
            <Button
              onClick={() => {
                setIsOpen(true)
              }}
              variant="text"
            >
              <RemoveCircleIcon sx={{ mr: 1 }} />
              {previousFormData.isEnabled ? <>Avaktivera</> : <>Aktivera</>}
            </Button>
          }
        />
      </Box>
      <ActivationDialog
        acceptAction={formProps.handleSubmit(onSetActivityAction)}
        isOpen={isOpen}
        type={previousFormData.isEnabled ? 'deactivate' : 'activate'}
        setIsOpen={setIsOpen}
      />
    </>
  )
}

export default UpdateLinkFormFields
