import { Arbetsgivarinfo, ArbetsgivarinfoCreate } from 'src/types'
import api from './api'

const arbetsgivarinformation = api.injectEndpoints({
  endpoints: (build) => ({
    getArbetsgivarinformationById: build.query<Arbetsgivarinfo, string>({
      query: (linkId: string) => `/arbetsgivarinfo/${linkId}`,
      keepUnusedDataFor: 0,
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getArbetsgivarinformation: build.query<Arbetsgivarinfo[], void>({
      query: () => '/arbetsgivarinfo',
      providesTags: ['Arbetsgivarinfos'],
    }),
    postArbetsgivarinformation: build.mutation<
      Arbetsgivarinfo,
      ArbetsgivarinfoCreate
    >({
      query: (body) => ({
        url: '/arbetsgivarinfo',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Arbetsgivarinfos'],
    }),
    putArbetsgivarinformation: build.mutation<
      Arbetsgivarinfo,
      { body: ArbetsgivarinfoCreate; linkId: string }
    >({
      query: ({ body, linkId }) => ({
        url: `/arbetsgivarinfo/${linkId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Arbetsgivarinfos'],
    }),
  }),
})

export const {
  useGetArbetsgivarinformationByIdQuery,
  useGetArbetsgivarinformationQuery,
  usePostArbetsgivarinformationMutation,
  usePutArbetsgivarinformationMutation,
} = arbetsgivarinformation
