import { useHistory } from 'react-router-dom'
import { useGetArbetsgivarinformationByIdQuery } from 'src/services'
import { UpdateLinkFormFields } from '..'

const UpdateLinkForm = () => {
  const {
    location: { search },
  } = useHistory()

  const linkId = new URLSearchParams(search).get('linkId') ?? ''

  const { data: previousFormData, isLoading } =
    useGetArbetsgivarinformationByIdQuery(linkId, {
      skip: !linkId,
    })
  if (isLoading || !previousFormData) {
    return null
  }
  return (
    <UpdateLinkFormFields linkId={linkId} previousFormData={previousFormData} />
  )
}

export default UpdateLinkForm
