import { Box } from '@mui/material'

const InfoCardImage = () => {
  return (
    <Box sx={{ backgroundColor: 'surface.purple' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ maxHeight: 225, width: '100%' }}
        viewBox="0 0 500 400"
      >
        <path
          fill="color(display-p3 .0314 .5686 .5333)"
          d="M57.229 377.536h142.813l43.809-.563a17.72 17.72 0 0 0 16.701-12.483l4.762-15.403c.212-.527.518-1.298.863-2.239 1.396-3.773 14.413-40.248-.954-61.487-8.258-11.412-15.728-6.009-23.719-17.271-10.481-14.768.592-26.564-7.626-43.776-8.486-17.773-31.043-27.713-49.177-26.551-14.67.94-31.219 9.455-30.9 15.708.088 1.719 1.465 3.516 1.25 3.643-.429.25-3.499-8.177-10.611-12.171-5.149-2.89-12.838-3.698-17.427-.13-3.112 2.418-4.219 3.284-5.413 11.581-1.11 7.718-7.158 13.775-14.879 14.869-1.614.228-2.62.765-3.076 1.032-5.556 3.278-8.554 13.544-7.503 27.641.531 7.129-6.174 10.804-14.1 18.821-19.745 19.972-30.308 14.96-39.87 28.559-9.671 13.749-9.173 33.523-2.914 48.063 5.114 11.884 13.492 18.918 17.97 22.16v-.003Z"
        />
        <path
          fill="color(display-p3 1 .7804 0)"
          d="M452.138 374.597c14.466-24.087 8.991-51.107-5.387-60.416-5.624-3.643-12.099-4.241-18.023-3.558-7.613.879-14.113-5.267-14.4-13.235-.537-14.846-9.195-28.006-21.313-32.218-9.544-3.317-20.816-.937-29.758 6.094-5.738 4.511-13.817 3.391-18.228-2.442-8.147-10.78-22.264-11.994-35.385-10.38-14.702 1.807-25.854 8.079-30.717 22.658-2.314 6.937-9.56 10.875-16.229 8.652-8.799-2.933-18.427-1.722-25.712 3.61-8.902 6.516-13.309 18.459-11.705 30.115 1.009 7.343-3.899 14.241-10.936 15.461-8.642 1.498-16.337 6.172-21.22 13.303-5.38 7.855-5.895 16.572-5.436 22.352h264.453l-.004.004Z"
        />
        <path
          fill="url(#a)"
          d="M497.673 378.727H2.324a2.325 2.325 0 0 1 0-4.648h495.352a2.326 2.326 0 0 1 2.324 2.324 2.325 2.325 0 0 1-2.324 2.324h-.003Z"
        />
        <path
          fill="#fff"
          d="M188.956 121h-44.99c-6.188 0-11.25 5.062-11.25 11.249v13.59c0 6.188 5.062 11.249 11.25 11.249h2.864c-1.357 2.266-3.802 5.625-3.802 5.625 4.893-.817 8.382-4.154 9.716-5.625h36.216c6.187 0 11.249-5.061 11.249-11.249v-13.59c0-6.187-5.062-11.249-11.249-11.249h-.004Zm67.232 26.548h44.991c6.187 0 11.249 5.061 11.249 11.249v13.59c0 6.187-5.062 11.249-11.249 11.249h-2.865c1.358 2.265 3.802 5.625 3.802 5.625-4.892-.817-8.381-4.154-9.716-5.625h-36.215c-6.188 0-11.249-5.062-11.249-11.249v-13.59c0-6.188 5.061-11.249 11.249-11.249h.003Z"
          opacity=".9"
        />
        <path
          fill="color(display-p3 .3843 .1333 .7647)"
          d="M154.427 144.394a4.408 4.408 0 1 0-.001-8.815 4.408 4.408 0 0 0 .001 8.815Zm12.034 0a4.408 4.408 0 1 0-.001-8.815 4.408 4.408 0 0 0 .001 8.815Zm12.03 0a4.408 4.408 0 1 0-.001-8.815 4.408 4.408 0 0 0 .001 8.815Zm87.38 26.329a4.407 4.407 0 1 0 0-8.814 4.407 4.407 0 0 0 0 8.814Zm12.031 0a4.407 4.407 0 1 0 0-8.814 4.407 4.407 0 0 0 0 8.814Zm12.034 0a4.407 4.407 0 1 0 0-8.814 4.407 4.407 0 0 0 0 8.814Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="m273.085 209.103 5.257 2.272a7.94 7.94 0 0 0 2.698.524l9.801.163c2.952 1.311 5.908 2.62 8.86 3.932 1.588-1.312 3.177-2.621 4.765-3.932-2.819-1.595-5.637-3.19-8.456-4.782-.082-.345-.43-3.688-3.617-4.111l-5.243-.358-3.249-1.621a1.067 1.067 0 0 0-1.185.14c-.628.518-.527 1.572.16 2.012l2.529 1.617s.475 1.068-.615 1.306c-1.091.237-5.345-.938-5.573-1.319-.153-.257-1.172-2.187-1.813-3.404-.299-.57-.94-.817-1.5-.583-.625.26-.925 1.025-.661 1.699l.032.088-.543.28c-.573.296-.788 1.051-.469 1.653l.837 1.576c.214.403.504.758.849 1.041l1.781 1.449-3.952-1.82c-.504-.198-1.064.033-1.312.541-.302.625-.013 1.399.612 1.634l.007.003Z"
        />
        <path
          fill="color(display-p3 .3843 .1333 .7647)"
          d="m386.847 197.6-30.812.325.993 93.946 34.773-.368 5.208-62.131-10.162-31.772Z"
        />
        <path
          fill="color(display-p3 .2157 .1765 .4)"
          d="M320.748 303.423h-5.371l2.129 2.063-38.363 69.335c-.511.921-.244 2.129.592 2.695.296.199.622.293.944.29.593-.007 1.165-.339 1.497-.934l38.042-68.756 72.417 70.227c.341.332.768.495 1.188.488.485-.006.966-.231 1.315-.664.644-.81.573-2.05-.166-2.763l-74.224-71.978v-.003Z"
        />
        <path
          fill="color(display-p3 .8314 .7569 .9333)"
          d="M383.648 201.014c1.142 24.006 1.634 46.872 1.5 70.891-.029 5.149-3.955 9.312-8.84 9.455a984.754 984.754 0 0 1-61.725-.117c-3.004-.101-5.494 2.438-5.472 5.605.023 3.431-.039 6.748.059 10.198.081 2.858 2.223 5.189 4.928 5.387 23.293 1.709 47.351 2.734 70.5 1.348 1.123-.069 2.246-.137 3.369-.212 10.12-.667 18.108-9.293 18.495-19.972.042-1.198.084-2.393.12-3.591.837-26.58.196-53.144-1.914-79.692-.127-1.604-1.354-2.877-2.877-2.988-4.987-.361-9.964.495-14.95.27-1.813-.081-3.291 1.504-3.2 3.415l.007.003Z"
        />
        <path
          fill="color(display-p3 .3843 .1333 .7647)"
          d="M365.273 147.199c5.992-1.266 16.011 1.979 16.337 10.712.062 1.628 1.477 2.836 3.098 2.696 4.87-.424 11.012 1.468 11.826 7.867.189 1.478 1.481 2.444 2.968 2.474 15.205.296 5.247 23.208-4.247 11.503-.944-1.165-2.65-1.309-3.858-.42-4.534 3.323-12.326 1.178-14.761-5.56-.579-1.604-2.33-2.395-3.89-1.699-9.459 4.212-20.031-7.434-16.392-21.365l-.127 2.32c.446-4.619 1.709-4.921 8.333-8.267a2.93 2.93 0 0 1 .71-.258l.003-.003Z"
        />
        <path
          fill="color(display-p3 .3843 .1333 .7647)"
          d="M342.69 134.544a14.773 14.773 0 0 1 4.541-1.478c4.596-.687 10.871 1.888 14.087 5.794 5.478 6.65-1.246 26.258-10.751 27.345-9.329 1.065-13.918-3.984-16.148-10.155-1.507-4.17-1.794-7.552-1.956-11.826.016-.566-.043-5.13 2.806-7.623 3.124-2.734 6.92-2.142 7.414-2.057h.007Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="M357.582 179.44c-2.575-1.107-7.477-4.759-7.477-4.759l1.647-9.231c-3.278 4.547-9.404 1.865-9.404 1.865l-1.543 5.976s-6.227 2.598-7.151 3.483c-.924.886 8.014 33.68 8.492 33.657.479-.023 17.502-27.658 17.838-28.153.016-.022.026-.039.036-.048-.14.159 2.066-.853-2.432-2.787l-.006-.003Z"
        />
        <path
          fill="color(display-p3 .5647 .2549 .1373)"
          d="m351.563 166.606 1.107-6.833a30.94 30.94 0 0 1-1.439 2.396c-1.852 2.588-5.712 4.235-8.811 4.495l-.075.651s5.244 2.36 9.218-.709Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="M356.989 151.066c-.765-.553-2.047.241-2.816.824-.003-.02-.009-.043-.013-.062-13.007.54-13.433-8.463-13.941-11.891-.013.023-2.086 4.212-5.009 5.986-2.467 16.79 2.819 21.428 8.323 20.982 3.307-.267 6.725-2.601 8.661-5.752.238-.419.459-.836.677-1.259.284.27.586.455.905.501 1.351.199 2.318-1.924 2.806-3.001.964-2.122 1.543-5.507.407-6.328Zm-4.635 8.19c.023.036.045.062.068.094-.091.062-.127.052-.068-.094Zm-52.438 188.23c1.064-19.159 1.54-34.311 1.748-44.932-.169-.303-.335-.606-.501-.908-10.244 1.962-15.442 2.701-18.056 2.965 1.514 19.165 4.919 39.044 5.69 43.405.016.313.062.612.159.889 1.328 3.857-.758 11.311-.758 11.311l7.952.488s5.963-4.612 4.817-6.132c-1.149-1.517-1.048-7.09-1.048-7.09l-.003.004Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="M340.018 343.323c-.756-.938-1.67-2.767-2.5-4.616-4.736-19.318-11.002-32.693-16.034-41.199-8.089 1.719-14.586 3.034-19.804 4.037l-.019 1.009c11.246 20.217 28.595 40.824 28.598 40.827 2.298 5.263 3.877 14.485 3.877 14.485l5.4-4.772s1.657-8.307.478-9.768l.004-.003Z"
        />
        <path
          fill="url(#b)"
          d="M292.667 272.484c-1.702 7.19 2.425 18.072 8.499 29.162.169-.033.348-.069.518-.101.201-11.015.11-16.861.11-16.861s36.87 2.714 57.044-2.52c20.175-5.234 9.199-30.538 9.199-30.538l-1.934-7.48-6.246 1.803c.013.02-.101.052-.345.101l-3.577 1.032-8.864 5-2.477-2.142-.231-.801a213.43 213.43 0 0 0-12.909 1.546c-17.18 4.095-36.248 11.064-38.79 21.796l.003.003Z"
        />
        <path
          fill="url(#c)"
          d="M301.163 301.646c.166.302.332.605.501.908l.019-1.009a59.42 59.42 0 0 0-.517.101h-.003Z"
        />
        <path
          fill="color(display-p3 .9922 .7922 .7098)"
          d="m334.839 177.643-4.059.684s-7.291 30.353-8.571 34.177c0 0-21.574-2.379-24.679-4.853l-3.001 7.347s23.14 15.917 35.395 10.656c12.255-5.26 12.782-24.213 12.782-24.213l-7.867-23.795v-.003Z"
        />
        <path
          fill="url(#d)"
          d="M301.163 301.646c.166.302.332.605.501.908l.019-1.009a59.42 59.42 0 0 0-.517.101h-.003Z"
        />
        <path
          fill="url(#e)"
          d="m359.857 245.953-.345.1c.244-.048.358-.081.345-.1Z"
        />
        <path
          fill="url(#f)"
          d="m359.857 245.953-.345.1c.244-.048.358-.081.345-.1Z"
        />
        <path
          fill="url(#g)"
          d="m350.111 248.765-1.409.407c3.281-.296 3.411-.466 1.409-.407Z"
        />
        <path
          fill="url(#h)"
          d="m350.111 248.765-1.409.407c3.281-.296 3.411-.466 1.409-.407Z"
        />
        <path
          fill="url(#i)"
          d="m348.702 249.172 1.409-.407c-1.207.036-3.193.153-5.745.377l.231.801 2.478 2.142 8.863-5-5.823 1.68c2.001-.059 1.871.111-1.41.407h-.003Z"
        />
        <path
          fill="color(display-p3 .251 .2118 .451)"
          d="M381.001 260.987c-.517-3.746-1.657-7.372-3.278-10.783-.781-1.644-1.774-3.174-2.857-4.639-1.146-1.546-2.767-3.86-3.861-5.94-.696-1.325-1.181-2.555-1.191-3.434l-7.207.075-21.864.231 3.32 11.578c-3.866.69-8.17 1.553-12.606 2.61-18.811 2.822-44.564 9.206-47.715 22.157-1.8 7.396-1.608 19.429-.632 31.766 2.614-.264 7.816-1.006 18.056-2.966l.517-.101c5.221-1.002 11.715-2.317 19.804-4.036-4.892-8.274-8.619-11.936-8.619-11.936s36.566 1.348 56.467-3.863c11.608-3.041 12.74-12.972 11.666-20.722v.003Z"
        />
        <path
          fill="color(display-p3 .8 .2863 0)"
          d="M333.439 375.895c-.449-.436-.758-.979-.967-1.591-.244-.723-.283-1.644-.205-2.65.218-2.858.886-6.712 1.153-8.535.208-1.425 1.022-4.352.478-6.103a17.14 17.14 0 0 0 3.945-4.036c3.053-4.378 2.262-7.184 2.178-9.661 2.783-.205 5.569.072 8.495 1.546.195.098.384.196.57.29 1.452.755 2.08 2.588 1.409 4.173-3.33 7.861-12.834 22.544-15.328 26.313-.4.609-1.22.743-1.728.254Zm-56.796-.429c-.124-.648-.072-1.296.097-1.931.202-.751.674-1.529 1.289-2.304 1.745-2.2 4.736-5.358 5.951-6.679.947-1.032 3.883-5.596 4.407-7.386 1.517.062 3.831 1.497 5.836.833 4.85-1.601 5.117-3.248 6.406-5.338 2.347 1.641 4.427 3.678 5.966 6.793.104.209.202.41.296.609.749 1.562.251 3.473-1.158 4.336-6.982 4.277-23.501 10.517-27.567 11.985-.654.237-1.387-.186-1.523-.918Zm56.972-169.511s-3.115-.739-5.647-4.192c-2.67-3.639-3.223-8.418-1.566-12.617 1.142-2.893 2.695-6.884 3.812-9.934.966-2.64 3.095-4.335 5.829-4.983l1.943-.388c.3-.058.551.225.456.511-.41 1.257-1.009 4.043.967 5.723 2.461 2.089 8.886 4.254 15.367-3.184 0 0 10.569 6.582 11.643 9.56 0 0-2.972 34.275-3.704 44.301a10.132 10.132 0 0 0 .296 3.359l-22.124 3.011-7.272-31.167Z"
        />
        <path
          fill="color(display-p3 .9922 .7922 .7098)"
          d="M366.917 187.2c-1.452-2.819-5.517-2.874-6.969-.055-3.216 6.246.345 22.482 1.845 28.608.515 2.099 1.729 7.558.554 8.567-.908.781-18.703 4.541-26.711 6.204l.957 7.203c11.272 1.475 41.57.593 44.835-8.271 3.417-9.273-10.263-34.008-14.511-42.256Z"
        />
        <path
          fill="color(display-p3 .2157 .1765 .4)"
          d="M348.272 303.423h-5.371l1.833 1.777-38.363 69.335c-.511.921-.245 2.128.592 2.695.296.198.622.293.944.29.592-.007 1.165-.339 1.497-.935l38.041-68.755 72.418 70.227c.341.332.768.494 1.191.488.485-.007.97-.231 1.315-.664.644-.814.573-2.051-.166-2.764l-73.924-71.688-.007-.006Z"
        />
        <path
          fill="color(display-p3 .3843 .1333 .7647)"
          d="M57.683 269.734v-82.651h49.645c5.318 0 9.631 4.16 9.631 9.293v85.753H70.53c-7.093 0-12.844-5.55-12.844-12.395h-.003Z"
        />
        <path
          fill="color(display-p3 1 .7804 0)"
          d="M117.451 365.138H98.217v-63.483h-5.455v63.483h-19.23a7.051 7.051 0 0 0-7.051 7.05v3.375h58.02v-3.375a7.05 7.05 0 0 0-7.05-7.05Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="M81.773 178.047c.02-.505 9.576-4.87 9.576-4.87l-1.308-8.192c3.818 5.321 10.979 2.203 10.979 2.203l.605 5.296s8.252 1.322 9.329 2.36c1.077 1.038-9.251 42.722-9.807 42.692-.56-.029-20.517-35.616-20.904-36.195-.02-.026-.03-.046-.039-.059.163.189 1.338 2.481 1.572-3.232l-.003-.003Z"
        />
        <path
          fill="color(display-p3 .5647 .2549 .1373)"
          d="m90.255 166.332-1.275-7.981a35.406 35.406 0 0 0 1.672 2.799c2.159 3.024 6.66 4.961 10.277 5.27l.087.762s-6.129 2.744-10.76-.853v.003Z"
        />
        <path fill="url(#j)" d="M81.464 179.717c-.023-.03-.023-.036 0 0Z" />
        <path
          fill="url(#k)"
          d="M89.273 157.859c.104.072.15.059.08-.11-.025.039-.054.071-.08.11Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="m196.635 343.27-6.89 3.399s5.946 11.067 6.015 13.134c.068 2.066 9.722 2.412 9.722 2.412l4.736-9.287s-5.208-1.432-6.734-5.573c-1.999-5.413-14.989-22.495-14.989-22.495l8.14 18.407v.003Z"
        />
        <path
          fill="url(#l)"
          d="m206.43 355.408-2.292-2.014s.374-2.048 3.61-4.688c0 0 6.881 3.389 10.663 2.79 2.048-.322 6.064-1.715 9.27-1.992-3.57 3.968-10.364 8.72-21.251 5.901v.003Z"
        />
        <path
          fill="url(#m)"
          d="M196.73 368.939c.107.225.215.446.319.655.826 1.673 2.867 2.386 4.651 1.598 8.85-3.916 26.151-15.448 30.408-18.362.687-.468.85-1.419.306-2.001-.481-.518-1.084-.87-1.767-1.104-.804-.277-1.836-.316-2.966-.218-3.57 3.968-10.363 8.72-21.251 5.901-7.253 4.447-11.276 3.246-11.276 3.246-.335 3.378-.114 6.747 1.576 10.285Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="m130.415 348.4-7.642-.791s-.85 12.535-1.895 14.322c-1.041 1.787 6.95 7.217 6.95 7.217l8.951-5.342s-3.646-3.984-2.737-8.3c1.188-5.647-.723-27.023-.723-27.023l-2.904 19.917Z"
        />
        <path
          fill="url(#n)"
          d="m132.252 363.888-.87-2.927s1.407-1.536 5.55-2.047c0 0 4.027 6.53 7.545 8.037 1.904.817 6.048 1.774 8.909 3.245-5.133 1.461-13.417 1.868-21.134-6.312v.004Z"
        />
        <path
          fill="url(#o)"
          d="M116.839 370.183c-.029.247-.055.491-.078.722-.189 1.856 1.159 3.545 3.089 3.828 9.579 1.393 30.362.834 35.515.632.83-.033 1.475-.749 1.325-1.53-.134-.693-.456-1.315-.908-1.875-.534-.661-1.387-1.243-2.396-1.761-5.133 1.462-13.417 1.868-21.135-6.311-8.505-.095-11.272-3.252-11.272-3.252-2.083 2.682-3.684 5.651-4.14 9.547Z"
        />
        <path
          fill="color(display-p3 .2157 .1765 .4)"
          d="m88.452 242.216-1.058 8.281s-8.443 28.634 14.44 31.44c22.879 2.805 61.506-2.923 61.506-2.923s-2.904 23.872 24.227 68.924l14.579-5.914s-10.751-61.259-19.097-82.417c-7.451-18.888-77.01-19.201-77.01-19.201l-17.59 1.81h.003Z"
        />
        <path
          fill="color(display-p3 .2392 .2157 .451)"
          d="m74.863 242.467-1.218 10.455s-7.281 27.081 12.454 29.734c19.735 2.653 44.61-.277 44.61-.277s-11.403 28.875-10.979 65.96h21.369s22.127-64.986 14.927-84.995c-6.425-17.863-61.161-19.709-61.161-19.709l-20.002-1.172v.004Z"
        />
        <path fill="url(#p)" d="M85.555 179.137c-.016-.022-.02-.029 0 0Z" />
        <path
          fill="color(display-p3 .7098 .9294 .9137)"
          d="m118.199 197.352-1.191-18.963c-1.67-1.888-2.594-2.487-6.246-3.734-.544-.185-5.319-1.425-5.836-1.549-.961 4.059-.954 5.908-4.87 6.539-4.567.736-8.906-1.181-10.666-5.804-2.227.398-14.482 6.706-16.562 8.89-1.644 7.402.664 50.426.257 64.4 2.943 1.038 29.416.518 40.662-6.725-.105-2.402.322-22.83 2.119-41.436.794-.534 1.572-1.074 2.337-1.618h-.004Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="M109.942 146.958c-.069-.865-.017-3.359-.127-4.293-.505-.019-1.214 1.084-1.732.817-1.439-.736-17.3-2.246-18.602-1.289-2.39 1.751-1.813 9.287-1.888 8.261-.033.01-.062.023-.094.033l-.017.071c-.895-.68-2.389-1.614-3.284-.97-1.331.957-.895 3.49.221 5.97.567 1.257 1.69 3.74 3.268 3.512.371-.052.726-.267 1.058-.582.254.491.514.983.788 1.471.062.101.13.198.195.299 2.285 3.535 6.152 6.129 9.905 6.442 5.875.488 11.546-3.991 10.309-19.745v.003Z"
        />
        <path
          fill="color(display-p3 .3843 .1333 .7647)"
          d="M83.94 136.806c1.13-.335 4.066 1.25 4.066 1.25h.023c1.436-2.858 3.366-8.382 7.33-8.609.886-.053 2.66.839 3.291 1.009.947-1.299 2.392-2.787 4.267-2.669a3.2 3.2 0 0 1 3.112 2.639s1.507-3.45 4.785-1.855c3.278 1.592.544 5.924.544 5.924l-.551.625c3.897 1.754 3.662 7.06-2.115 8.596-1.973.525-4.15.844-6.302 1.026.368 5.432-12.366-.095-12.366-.095.508 5.042-2.678 6.068-2.678 6.068s-9.788-12.011-3.405-13.912v.003Z"
        />
        <path
          fill="color(display-p3 .8314 .7569 .9333)"
          d="M43.344 187.08h16.057v82.65c0 6.846 5.443 12.395 12.158 12.395h57.304c3.854 0 6.979 3.187 6.979 7.116v5.296c0 3.928-3.125 7.115-6.979 7.115H66.54c-13.974 0-25.302-11.549-25.302-25.792v-86.639c0-1.185.941-2.145 2.106-2.145v.004Z"
        />
        <path
          fill="color(display-p3 .9176 .9804 .9765)"
          d="M107.276 224.388s-27.385 1.891-30.034-2.168c-3.17-4.853 7.438-29.929 1.592-37.679-1.83-2.425-4.974-3.786-6.553-1.192-5.377 8.838-21.994 38.881-11.366 54.46 5.364 7.864 46.914-4.775 46.914-4.775l-.557-8.646h.004Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="m152.123 188.753 9.801-.163a7.94 7.94 0 0 0 2.698-.524l5.257-2.272c.625-.234.915-1.009.612-1.634-.244-.508-.804-.739-1.312-.54l-3.952 1.819 1.781-1.448a3.41 3.41 0 0 0 .85-1.042l.836-1.575c.319-.599.104-1.358-.469-1.654l-.543-.28.032-.088c.264-.67-.036-1.435-.66-1.699-.56-.234-1.202.016-1.501.583-.641 1.217-1.66 3.147-1.813 3.405-.228.38-4.482 1.552-5.573 1.318-1.087-.238-.615-1.305-.615-1.305l2.529-1.618c.687-.44.785-1.494.16-2.012l-.036-.029c-.332-.26-.771-.3-1.149-.111l-3.248 1.621-5.244.358c-3.187.423-3.535 3.766-3.617 4.111l-6.597 10.348 7.61 5.953 4.16-11.516.003-.006Z"
        />
        <path
          fill="color(display-p3 .9176 .9804 .9765)"
          d="m144.949 214.653 8.733-23.84-12.727-3.515-7.509 20.161-15.621-27.96c-2.855-3.271-6.8-4.271-10.191-1.117-2.91 2.709-3.568 7.9-1.608 11.367 2.451 4.332 5.859 10.028 9.7 15.49 3.43 4.883 7.2 9.577 10.943 12.949 2.48 2.233 5.843 4.247 9.172 4.137h.085c4.131-.166 7.591-3.769 9.02-7.666l.003-.006Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="M133.377 231.204c-.12-.576-.625-.95-1.184-.878l-4.443.83 2.161-1.01c.42-.195.801-.478 1.12-.833l1.24-1.377c.472-.524.446-1.344-.059-1.787l-.478-.42.055-.078c.433-.612.325-1.458-.238-1.881-.504-.378-1.217-.29-1.66.205-.953 1.064-2.467 2.75-2.685 2.972-.326.325-4.915.423-5.953-.091-1.039-.515-.29-1.475-.29-1.475l2.959-.986c.804-.267 1.168-1.309.667-1.989a1.117 1.117 0 0 0-1.159-.446l-3.685.807-5.377-.976c-3.32-.387-4.514 2.897-4.684 3.226l-2.321.309c.16 2.304.319 4.606.476 6.91l5.852-1.077 9.928 2.334a8.243 8.243 0 0 0 2.855.159l5.875-.954c.69-.078 1.178-.784 1.032-1.49l-.004-.004Z"
        />
        <path
          fill="#fff"
          d="M292.091 212.049h-4.355c-.69 0-1.179.674-.97 1.334l10.413 33.012c.133.427.527.717.969.717h25.601c-7.249-1.468-13.957-4.782-19.338-9.827-8.704-8.157-11.461-18.508-12.32-25.236Z"
        />
        <path
          fill="#fff"
          d="m325.754 245.777-10.413-33.012a1.017 1.017 0 0 0-.97-.716h-22.277c.856 6.725 3.616 17.079 12.32 25.236 5.384 5.045 12.093 8.359 19.338 9.827h1.032c.69 0 1.178-.674.97-1.335Z"
        />
        <path
          fill="url(#q)"
          d="m328.462 231.253.804-.446s-1.65-1.312-3.04-1.562c-1.39-.251-5.917-.729-5.917-.729l1.132 3.593 7.021-.859v.003Z"
        />
        <path
          fill="color(display-p3 .7843 .4078 .2588)"
          d="M329.27 230.811c-.137-.007-1.563-1.273-3.122-.808-1.562.462-6.142 2.956-6.614 2.871 0 0-5.735-3.574-7.747-4.026-2.012-.453-2.685 1.191-2.601 1.344.085.153.921.544.921.544-.419.035-.771.374-.856.83l-.035.185c-.095.505.159 1.003.598 1.175l1.195.466-.218-.059c-.537-.146-1.087.212-1.227.801-.127.537.127 1.09.596 1.295l8.179 3.555a6.984 6.984 0 0 0 4.736.309l5.732-1.65s1.791-.209 7.829.036l-.954-6.79-6.412-.075v-.003Z"
        />
        <defs>
          <linearGradient
            id="a"
            x1="186.918"
            x2="313.078"
            y1="267.146"
            y2="485.664"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .4196 .4824 .9216)" />
            <stop
              offset=".03"
              stopColor="color(display-p3 .4078 .4588 .8863)"
            />
            <stop offset=".1" stopColor="color(display-p3 .3686 .3922 .7843)" />
            <stop offset=".17" stopColor="color(display-p3 .3373 .3373 .698)" />
            <stop offset=".27" stopColor="color(display-p3 .3137 .298 .6314)" />
            <stop offset=".38" stopColor="color(display-p3 .298 .2667 .5843)" />
            <stop offset=".54" stopColor="color(display-p3 .2863 .251 .5569)" />
            <stop
              offset=".98"
              stopColor="color(display-p3 .2863 .2471 .5529)"
            />
          </linearGradient>
          <linearGradient
            id="b"
            x1="313.355"
            x2="357.187"
            y1="246.331"
            y2="303.682"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .5529 .5804 1)" />
            <stop
              offset=".02"
              stopColor="color(display-p3 .5373 .5647 .9725)"
            />
            <stop
              offset=".09"
              stopColor="color(display-p3 .4745 .5098 .8706)"
            />
            <stop
              offset=".17"
              stopColor="color(display-p3 .4235 .4627 .7843)"
            />
            <stop
              offset=".27"
              stopColor="color(display-p3 .3882 .4275 .7176)"
            />
            <stop
              offset=".38"
              stopColor="color(display-p3 .3608 .4039 .6706)"
            />
            <stop
              offset=".55"
              stopColor="color(display-p3 .3451 .3882 .6431)"
            />
            <stop offset="1" stopColor="color(display-p3 .3412 .3882 .6392)" />
          </linearGradient>
          <linearGradient
            id="c"
            x1="279.115"
            x2="322.945"
            y1="272.501"
            y2="329.85"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .5529 .5804 1)" />
            <stop
              offset=".02"
              stopColor="color(display-p3 .5373 .5647 .9725)"
            />
            <stop
              offset=".09"
              stopColor="color(display-p3 .4745 .5098 .8706)"
            />
            <stop
              offset=".17"
              stopColor="color(display-p3 .4235 .4627 .7843)"
            />
            <stop
              offset=".27"
              stopColor="color(display-p3 .3882 .4275 .7176)"
            />
            <stop
              offset=".38"
              stopColor="color(display-p3 .3608 .4039 .6706)"
            />
            <stop
              offset=".55"
              stopColor="color(display-p3 .3451 .3882 .6431)"
            />
            <stop offset="1" stopColor="color(display-p3 .3412 .3882 .6392)" />
          </linearGradient>
          <linearGradient
            id="d"
            x1="282.206"
            x2="332.572"
            y1="276.544"
            y2="342.449"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .5529 .5804 1)" />
            <stop
              offset=".08"
              stopColor="color(display-p3 .5059 .5373 .9255)"
            />
            <stop
              offset=".21"
              stopColor="color(display-p3 .4471 .4824 .8196)"
            />
            <stop offset=".35" stopColor="color(display-p3 .4 .4392 .7373)" />
            <stop
              offset=".51"
              stopColor="color(display-p3 .3647 .4078 .6824)"
            />
            <stop offset=".7" stopColor="color(display-p3 .3451 .3922 .6471)" />
            <stop offset="1" stopColor="color(display-p3 .3412 .3882 .6392)" />
          </linearGradient>
          <linearGradient
            id="e"
            x1="342.775"
            x2="386.606"
            y1="223.849"
            y2="281.202"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .5529 .5804 1)" />
            <stop
              offset=".02"
              stopColor="color(display-p3 .5373 .5647 .9725)"
            />
            <stop
              offset=".09"
              stopColor="color(display-p3 .4745 .5098 .8706)"
            />
            <stop
              offset=".17"
              stopColor="color(display-p3 .4235 .4627 .7843)"
            />
            <stop
              offset=".27"
              stopColor="color(display-p3 .3882 .4275 .7176)"
            />
            <stop
              offset=".38"
              stopColor="color(display-p3 .3608 .4039 .6706)"
            />
            <stop
              offset=".55"
              stopColor="color(display-p3 .3451 .3882 .6431)"
            />
            <stop offset="1" stopColor="color(display-p3 .3412 .3882 .6392)" />
          </linearGradient>
          <linearGradient
            id="f"
            x1="345.866"
            x2="396.236"
            y1="227.893"
            y2="293.802"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .5529 .5804 1)" />
            <stop
              offset=".08"
              stopColor="color(display-p3 .5059 .5373 .9255)"
            />
            <stop
              offset=".21"
              stopColor="color(display-p3 .4471 .4824 .8196)"
            />
            <stop offset=".35" stopColor="color(display-p3 .4 .4392 .7373)" />
            <stop
              offset=".51"
              stopColor="color(display-p3 .3647 .4078 .6824)"
            />
            <stop offset=".7" stopColor="color(display-p3 .3451 .3922 .6471)" />
            <stop offset="1" stopColor="color(display-p3 .3412 .3882 .6392)" />
          </linearGradient>
          <linearGradient
            id="g"
            x1="335.262"
            x2="379.098"
            y1="229.59"
            y2="286.94"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .5529 .5804 1)" />
            <stop
              offset=".02"
              stopColor="color(display-p3 .5373 .5647 .9725)"
            />
            <stop
              offset=".09"
              stopColor="color(display-p3 .4745 .5098 .8706)"
            />
            <stop
              offset=".17"
              stopColor="color(display-p3 .4235 .4627 .7843)"
            />
            <stop
              offset=".27"
              stopColor="color(display-p3 .3882 .4275 .7176)"
            />
            <stop
              offset=".38"
              stopColor="color(display-p3 .3608 .4039 .6706)"
            />
            <stop
              offset=".55"
              stopColor="color(display-p3 .3451 .3882 .6431)"
            />
            <stop offset="1" stopColor="color(display-p3 .3412 .3882 .6392)" />
          </linearGradient>
          <linearGradient
            id="h"
            x1="338.354"
            x2="388.728"
            y1="233.633"
            y2="299.542"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .5529 .5804 1)" />
            <stop
              offset=".08"
              stopColor="color(display-p3 .5059 .5373 .9255)"
            />
            <stop
              offset=".21"
              stopColor="color(display-p3 .4471 .4824 .8196)"
            />
            <stop offset=".35" stopColor="color(display-p3 .4 .4392 .7373)" />
            <stop
              offset=".51"
              stopColor="color(display-p3 .3647 .4078 .6824)"
            />
            <stop offset=".7" stopColor="color(display-p3 .3451 .3922 .6471)" />
            <stop offset="1" stopColor="color(display-p3 .3412 .3882 .6392)" />
          </linearGradient>
          <linearGradient
            id="i"
            x1="335.618"
            x2="379.454"
            y1="229.317"
            y2="286.671"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .5529 .5804 1)" />
            <stop
              offset=".02"
              stopColor="color(display-p3 .5373 .5647 .9725)"
            />
            <stop
              offset=".09"
              stopColor="color(display-p3 .4745 .5098 .8706)"
            />
            <stop
              offset=".17"
              stopColor="color(display-p3 .4235 .4627 .7843)"
            />
            <stop
              offset=".27"
              stopColor="color(display-p3 .3882 .4275 .7176)"
            />
            <stop
              offset=".38"
              stopColor="color(display-p3 .3608 .4039 .6706)"
            />
            <stop
              offset=".55"
              stopColor="color(display-p3 .3451 .3882 .6431)"
            />
            <stop offset="1" stopColor="color(display-p3 .3412 .3882 .6392)" />
          </linearGradient>
          <linearGradient
            id="j"
            x1="112.522"
            x2="81.444"
            y1="180.514"
            y2="179.695"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 1 .6196 .6941)" />
            <stop
              offset=".33"
              stopColor="color(display-p3 .9961 .6667 .7412)"
            />
            <stop offset=".9" stopColor="color(display-p3 .9843 .7961 .8667)" />
            <stop offset="1" stopColor="color(display-p3 .9843 .8235 .8902)" />
          </linearGradient>
          <linearGradient
            id="k"
            x1="89.399"
            x2="89.285"
            y1="157.826"
            y2="157.832"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .2588 .3451 .6353)" />
            <stop
              offset=".25"
              stopColor="color(display-p3 .2471 .2667 .5294)"
            />
            <stop
              offset=".51"
              stopColor="color(display-p3 .2353 .2039 .4471)"
            />
            <stop offset=".76" stopColor="color(display-p3 .2314 .1686 .4)" />
            <stop offset="1" stopColor="color(display-p3 .2314 .1569 .3843)" />
          </linearGradient>
          <linearGradient
            id="l"
            x1="452.443"
            x2="369.471"
            y1="171.085"
            y2="234.104"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .2588 .3451 .6353)" />
            <stop offset="1" stopColor="color(display-p3 .2314 .1569 .3843)" />
          </linearGradient>
          <linearGradient
            id="m"
            x1="363.088"
            x2="324.001"
            y1="-13.266"
            y2="83.318"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .2588 .3451 .6353)" />
            <stop offset="1" stopColor="color(display-p3 .2314 .1569 .3843)" />
          </linearGradient>
          <linearGradient
            id="n"
            x1="779.94"
            x2="691.127"
            y1="262.724"
            y2="276.981"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .2588 .3451 .6353)" />
            <stop offset="1" stopColor="color(display-p3 .2314 .1569 .3843)" />
          </linearGradient>
          <linearGradient
            id="o"
            x1="655.887"
            x2="586.847"
            y1="-64.192"
            y2="-6.524"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .2588 .3451 .6353)" />
            <stop offset="1" stopColor="color(display-p3 .2314 .1569 .3843)" />
          </linearGradient>
          <linearGradient
            id="p"
            x1="110.134"
            x2="85.542"
            y1="179.128"
            y2="179.128"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 1 .6235 .698)" />
            <stop
              offset=".33"
              stopColor="color(display-p3 .9961 .6706 .7451)"
            />
            <stop offset=".92" stopColor="color(display-p3 .9843 .8 .8706)" />
            <stop offset="1" stopColor="color(display-p3 .9843 .8235 .8902)" />
          </linearGradient>
          <linearGradient
            id="q"
            x1="315.416"
            x2="353.539"
            y1="230.478"
            y2="229.346"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="color(display-p3 .7882 .4 .2588)" />
            <stop offset=".11" stopColor="color(display-p3 .749 .3804 .2627)" />
            <stop offset=".4" stopColor="color(display-p3 .6745 .349 .2745)" />
            <stop
              offset=".69"
              stopColor="color(display-p3 .6275 .3294 .2824)"
            />
            <stop offset="1" stopColor="color(display-p3 .6157 .3255 .2863)" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}

export default InfoCardImage
