import { Box, Button, Typography } from '@mui/material'

import ValidatedLinkImage from '../LinkInvalid/components/ValidatedLinkImage/ValidatedLinkImage'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'src/types'

const LinkAuthentication = ({ linkId }: { linkId: string }) => {
  const { forsattsbladContent } = useEpiContent<Content>()
  const {
    validLinkHeader,
    validLinkSectionOneTitle,
    validLinkSectionOneParagraph,
    validLinkSectionTwoTitle,
    validLinkSectionTwoParagraph,
    validLinkContinueButtonText,
    validLinkSecondaryButtonText,
  } = forsattsbladContent

  return (
    <>
      <Typography variant="display" textOverflow="overflow">
        {validLinkHeader}
      </Typography>
      <Box display="flex" gap={2} data-testid="link-validated">
        <Box flexBasis={'50%'} alignSelf="center" pr={5}>
          <Typography variant="h6" mt={3}>
            {validLinkSectionOneTitle}
          </Typography>
          <Typography variant="body1" mb={4}>
            {validLinkSectionOneParagraph}
          </Typography>
          <Typography variant="h6" mt={3}>
            {validLinkSectionTwoTitle}
          </Typography>
          <Typography variant="body1" mb={4}>
            {validLinkSectionTwoParagraph}
          </Typography>
          <Box display="flex" mb={4} gap={1}>
            <Button href={`/login/arbetsgivarinfo?linkId=${linkId}`}>
              {validLinkContinueButtonText}
            </Button>
            <Button href="/om-oss" variant="text" size="small">
              {validLinkSecondaryButtonText}
            </Button>
          </Box>
        </Box>
        <Box flexBasis={'50%'} pl={5}>
          <ValidatedLinkImage />
        </Box>
      </Box>
    </>
  )
}

export default LinkAuthentication
