import { Box, Button, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from 'src/types'
import InvalidLinkImage from './components/InvalidLinkImage'

const LinkInvalid = () => {
  const { forsattsbladContent } = useEpiContent<Content>()
  const {
    invalidLinkHeader,
    invalidLinkSectionOneTitle,
    invalidLinkSectionOneParagraph,
    invalidLinkButtonText,
  } = forsattsbladContent
  return (
    <Box display="flex" gap={2}>
      <Box
        data-testid="link-invalid"
        flexBasis={'50%'}
        alignSelf="center"
        pr={5}
      >
        <Typography variant="display">{invalidLinkHeader}</Typography>
        <Typography variant="h6" mt={3}>
          {invalidLinkSectionOneTitle}
        </Typography>
        <Typography variant="body1" mb={4}>
          {invalidLinkSectionOneParagraph}
        </Typography>
        <Box display="flex" mb={4}>
          <Button href="/" variant="text" size="small">
            {invalidLinkButtonText}
          </Button>
        </Box>
      </Box>
      <Box flexBasis={'50%'} pl={5}>
        <InvalidLinkImage />
      </Box>
    </Box>
  )
}

export default LinkInvalid
