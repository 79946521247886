import Add from '@mui/icons-material/Add'
import { Box, Button, CircularProgress, Typography } from '@mui/material'

import { LinkDetailsCard } from '../'
import { useGetArbetsgivarinformationQuery } from 'src/services'
import NoActiveLinks from '../NoActiveLinks'
import { Arbetsgivarinfo } from 'src/types'

const ViewLinksMain = () => {
  const {
    data: arbetsgivarInfo,
    isLoading,
    isSuccess,
  } = useGetArbetsgivarinformationQuery()

  const enabledLinks: Arbetsgivarinfo[] = []
  const disabledLinks: Arbetsgivarinfo[] = []
  const outDatedLinks: Arbetsgivarinfo[] = []

  arbetsgivarInfo?.forEach((link) => {
    if (
      new Date(link.endTime).setHours(23, 59, 59, 0) <
      new Date().setHours(0, 0, 0, 0)
    ) {
      outDatedLinks.push(link)
      return
    }
    if (!link.isEnabled) {
      disabledLinks.push(link)
      return
    }
    enabledLinks.push(link)
    return
  })

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2} maxWidth={650}>
        <Typography variant="body1" fontWeight="bold">
          Aktiva länkar
        </Typography>
        <Button
          variant="outlined"
          size="small"
          startIcon={<Add />}
          href="/trr-foretag/dina-lankar/skapa"
          data-testid="create-link-button"
        >
          Skapa ny
        </Button>
      </Box>
      {isLoading && <CircularProgress />}
      {isSuccess && enabledLinks.length === 0 && <NoActiveLinks />}
      {enabledLinks.map((link) => (
        <LinkDetailsCard
          key={link.id}
          title={link.name}
          visits={link.userCount}
          linkId={link.id}
          startDate={link.startTime}
          endDate={link.endTime}
        />
      ))}
      {outDatedLinks.length > 0 && (
        <Box>
          <Box display="flex" justifyContent="flex-start" mb={2}>
            <Typography variant="body1" fontWeight="bold">
              Utgångna länkar
            </Typography>
          </Box>
          {outDatedLinks.map((link) => (
            <LinkDetailsCard
              key={link.id}
              title={link.name}
              visits={link.userCount}
              linkId={link.id}
              startDate={link.startTime}
              endDate={link.endTime}
              isEnabled={false}
            />
          ))}
        </Box>
      )}
      {disabledLinks.length > 0 && (
        <Box>
          <Box display="flex" justifyContent="flex-start" mb={2}>
            <Typography variant="body1" fontWeight="bold">
              Inaktiva länkar
            </Typography>
          </Box>
          {disabledLinks.map((link) => (
            <LinkDetailsCard
              key={link.id}
              title={link.name}
              visits={link.userCount}
              linkId={link.id}
              startDate={link.startTime}
              endDate={link.endTime}
              isEnabled={false}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

export default ViewLinksMain
