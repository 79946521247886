import {
  type AppShellDataProps,
  AppShellDataProvider,
} from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { createTheme, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { sv } from 'date-fns/locale/sv'
import App from 'App'
import { Provider } from 'react-redux'
import { store } from './Store'

type Content = {
  heading: string
}

const Index = ({
  appShellData,
}: {
  appShellData: AppShellDataProps<Content>
}) => {
  const theme = createTheme(themeOptions, defaultLocale, sv)
  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
            <App />
          </LocalizationProvider>
        </Provider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default Index
