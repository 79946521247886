import { BasePage } from 'src/features/MittForetagView/components'
import { ViewLinksMain, InfoCard } from './components'

const ViewLinks = () => {
  return (
    <BasePage mainComponent={<ViewLinksMain />} sideComponent={<InfoCard />} />
  )
}

export default ViewLinks
