const texts = {
  informationFieldHelperMessage:
    'Texten visas på den sida som tjänstemannen landar på efter inloggning. Ange ingen känslig eller personlig information.',
  startTimeHelperMessage: 'Du måste ange ett startdatum för länken',
  endTimeHelperMessage: 'Länken upphör gälla detta datum',
  nameHelperMessage: 'Ge länken ett namn',

  informationFieldErrorMessage: 'Du måste inkludera ett meddelande',
  startTimeErrorMessage: 'Du måste ange ett startdatum för länken',
  endTimeErrorMessage: 'Du måste ange ett slutdatum för länken',
  nameErrorMessage: 'Du måste ange ett namn för länken',
  endTimeBeforeStartTimeErrorMessage:
    'Ditt slutdatum får inte vara innan startdatumet',
}

export default texts
