import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from '@mui/material'
import { Dispatch } from 'react'
import { useHistory } from 'react-router-dom'

interface ActivationDialogProps {
  acceptAction: () => Promise<void>
  isOpen: boolean
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
  type: 'activate' | 'deactivate'
}

const content = {
  activate: {
    title: 'Aktivera länken?',
    body: 'När du aktiverar länken kan personer med länken komma åt den. Du kan när som helst avaktivera länken igen.',
    cancel: 'Avbryt',
    accept: 'Aktivera länken',
  },
  deactivate: {
    title: 'Avaktivera länken?',
    body: 'När du avaktiverar länken kan personer med länken inte längre komma åt den. Du kan när som helst aktivera länken igen. Inaktiva länkar tas bort automatiskt efter 6 månader.',
    cancel: 'Avbryt',
    accept: 'Avaktivera länken',
  },
}

const ActivationDialog = ({
  acceptAction,
  isOpen,
  setIsOpen,
  type,
}: ActivationDialogProps) => {
  const history = useHistory()

  const contentByType = content[type]

  const handleAccept = () => {
    setIsOpen(false)
    void acceptAction().finally(() => {
      history.replace('/trr-foretag/dina-lankar')
    })
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>{contentByType.title}</DialogTitle>
      <DialogContent>
        <DialogContentText mb={3}>{contentByType.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" size="small" onClick={handleCancel}>
          {contentByType.cancel}
        </Button>
        <Button variant="text" size="small" onClick={handleAccept}>
          {contentByType.accept}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ActivationDialog
