import { useEffect, useState } from 'react'
import { useGetArbetsgivarinformationByIdQuery } from 'src/services'

const useLinkValidation = () => {
  const [isLinkValidated, setIsLinkValidated] = useState(false)

  const urlParameters = new URLSearchParams(window.location.search)
  const linkId = urlParameters.get('linkId') ?? ''

  const { isSuccess, isLoading } = useGetArbetsgivarinformationByIdQuery(linkId)
  useEffect(() => {
    if (isSuccess) setIsLinkValidated(true)
  }, [isSuccess, setIsLinkValidated])

  return {
    isLinkValidated,
    isLoading,
    linkId,
  }
}

export default useLinkValidation
